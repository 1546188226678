import localStorage from './localStorage.js';

const dixaDefaultServiceDomain = 'dixa.io';

const websocket = `wss://sockets.${dixaDefaultServiceDomain}/v1/enduser/`;
const restApi = `https://api.${dixaDefaultServiceDomain}/`;
const filesApi = `https://files.${dixaDefaultServiceDomain}`;
const widgetApiDomain = `widget.${dixaDefaultServiceDomain}`;

const config = {
  WEB_SOCKET_URL: localStorage.get('_dixa-websocket-url') || websocket,
  REST_API_URL: localStorage.get('_dixa-rest-api-url') || restApi,
  FILES_API_URL: localStorage.get('_dixa-files-api-url') || filesApi,
  SETTINGS: localStorage.get('_dixa-settings', true) || null,
  WIDGET_STATE: localStorage.get('dixa-widget', true) || null,
  WIDGET_API_DOMAIN: widgetApiDomain,
};

export default config;
