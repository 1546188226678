// @flow
/**
 * Merge translated strings with
 * @param  {object} translated Object with key for translation and value with the local string
 * @param  {string} locale     "da" "es-ES", "de-DE", "pt-PT", "pl-PL", "en-US", "sv", "fi", "nl" or "no".
 * @param  {map} localization from settings
 * @return {map} A union map translated object between organization specific translations and the default.
 */
export function mergeWithOrgSpecificTranslations(
  translated,
  locale,
  localization,
) {
  if (
    localization[locale] === undefined ||
    localization[locale].minimized === undefined ||
    localization[locale].minimized.html_text_online === undefined ||
    localization[locale].minimized.html_text_offline === undefined
  ) {
    return Object.assign({}, _flattenMessages(translated));
  }
  const organizationSpecificTranslation = {
    view_state: {
      minimized: {
        click_to_open_conversation:
          translated.view_state.welcome.click_to_open_conversation,
        html_text_online: localization[locale].minimized.html_text_online,
        html_text_offline: localization[locale].minimized.html_text_offline,
      },
      welcome: {
        click_to_open_conversation:
          translated.view_state.welcome.click_to_open_conversation,
        html_text_online: localization[locale].minimized.html_text_online,
        html_text_offline: localization[locale].minimized.html_text_offline,
      },
    },
    overlays: translated.overlays,
  };

  if (
    localization[locale].overlay !== undefined &&
    localization[locale].overlay.out_of_office !== undefined &&
    localization[locale].overlay.out_of_office.notes !== undefined
  ) {
    organizationSpecificTranslation.overlays.out_of_office.notes =
      localization[locale].overlay.out_of_office.notes;
    // Make sure we have all the keys in our string array (with a fallback to en-US).
  }

  return Object.assign(
    {},
    _flattenMessages(translated),
    _flattenMessages(organizationSpecificTranslation),
  );
}

function _flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, _flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}
// Right to left locales.
export const RTL_LOCALES = ['ar'];

export const SUPPORTED_LOCALE_MAP = {
  'en-US': { aliases: ['en-US'], name: 'English' },
  no: { aliases: ['no', 'nb', 'nn', 'nb-NO', 'nb-NN'], name: 'Norsk' },
  da: {
    aliases: ['da-DK', 'da'],
    name: 'Dansk',
  },
  'pl-PL': { aliases: ['pl', 'pl-PL'], name: 'Polski' },
  'fr-FR': {
    aliases: [
      'fr-FR',
      'fr',
      'fr-be',
      'fr-ca',
      'fr-lu',
      'fr-mc',
      'fr-ch',
      'fr-FR',
    ],
    name: 'Français',
  },
  sv: { aliases: ['sv-SE', 'sv-SV', 'sv'], name: 'Svenska' },
  'es-ES': {
    aliases: [
      'es',
      'es-VE',
      'es-PE',
      'es-PR',
      'es-MX',
      'es-CO',
      'es-AR',
      'es-XL',
      'es-ES',
    ],
    name: 'Español',
  },
  fi: { aliases: ['fi-FI', 'fi'], name: 'Suomi' },
  'pt-PT': { aliases: ['pt-BR', 'pt', 'pt-PT'], name: 'Português' },
  'de-DE': {
    aliases: ['de', 'de-CH', 'de-LU', 'de-LI', 'de-AT', 'de-DE'],
    name: 'Deutsch',
  },
  'it-IT': { aliases: ['it', 'it-CH', 'it-IT'], name: 'Italiano' },
  nl: { aliases: ['nl', 'nl-BE', 'nl-NL'], name: 'Nederlands' },
  'lt-LT': { aliases: ['lt', 'lt-LT'], name: 'Lietuviškai' },
  'lv-LV': { aliases: ['lv', 'lv-LV'], name: 'Latviešu' },
  'ru-RU': { aliases: ['ru-MO', 'ru', 'ru-RU'], name: 'Pусский' },
  'ro-RO': { aliases: ['ro', 'ro-MD', 'ro-RO'], name: 'Română' },
  'id-ID': { aliases: ['id', 'id-ID'], name: 'bahasa Indonesia' },
  'ms-MY': { aliases: ['ms', 'ms-MY'], name: 'Malay' },
  'ja-JP': { aliases: ['ja', 'ja-JP'], name: '日本語' },
  'zh-CN': {
    aliases: ['zh-TW', 'zh-HK', 'zh-MO', 'zh-SG', 'zh-CN'],
    name: '中文',
  },
  'el-GR': { aliases: ['el-GR'], name: 'Ελληνικά' },
  'tr-TR': { aliases: ['tr-TR'], name: 'Türk' },
  'hi-IN': { aliases: ['hi-IN'], name: 'हिंदी' },
  'cs-CZ': { aliases: ['cs-CZ'], name: 'Čeština' },
  'ko-KR': { aliases: ['ko-KR'], name: '한국어' },
  'vi-VN': { aliases: ['vi-VN'], name: 'Tiếng Việt' },
  'th-TH': { aliases: ['th-TH'], name: 'ไทย' },
  'hu-HU': { aliases: ['hu-HU'], name: 'Magyar' },
  'hr-HR': { aliases: ['hr-HR'], name: 'Hırvat' },
  'et-EE': { aliases: ['et', 'et-EE'], name: 'Eesti keel' },
  is: { aliases: ['is'], name: 'Íslensku' },
  ar: {
    aliases: [
      'ar',
      'ar-AE',
      'ar-BH',
      'ar-DZ',
      'ar-EG',
      'ar-IQ',
      'ar-JO',
      'ar-KW',
      'ar-LB',
      'ar-LY',
      'ar-MA',
      'ar-OM',
      'ar-QA',
      'ar-SA',
      'ar-SY',
      'ar-TN',
      'ar-YE',
    ],
    name: 'العربية',
  },
};

export const SUPPORTED_LOCALES: Array<string> =
  Object.keys(SUPPORTED_LOCALE_MAP);

/**
 * Returns a common locale name used across application
 * @param {string} locale a locale value to get common name of
 */
export function getCommonLocale(locale: string): string {
  if (!locale) {
    return locale;
  }

  return (
    SUPPORTED_LOCALES.find(
      (key) =>
        locale === key || SUPPORTED_LOCALE_MAP[key].aliases.includes(locale),
    ) || locale
  );
}

/**
 * Returns a boolean value indicating whether the locale is valid or supported by the app
 * @param {string} locale a locale value to verify
 */
export function isValidLocale(locale: string): boolean {
  return SUPPORTED_LOCALES.includes(locale);
}
