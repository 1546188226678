const localStorageHelper = {
  remove(name) {
    if (name.trim() !== '') {
      return localStorage.removeItem(name);
    }
    return false;
  },

  set(name, item, saveAsJSON) {
    const maybeStringifiedItem =
      saveAsJSON === true ? JSON.stringify(item) : item;
    if (maybeStringifiedItem != null && name.trim() !== '') {
      return localStorage.setItem(name, item);
    }
    return false;
  },

  get(name, getAsJSON) {
    if (name.trim() !== '') {
      const itemValue = localStorage.getItem(name);
      if (itemValue != null) {
        if (getAsJSON === true) {
          return JSON.parse(itemValue);
        }
        return itemValue;
      }
      return false;
    }
    return false;
  },
};

export default localStorageHelper;
