// @flow
import config from './utils/config.js';
import positionsStyles from '../json/positionsStyles.json';
import { getCommonLocale } from './utils/locale';

// Replacement for Object.assign, since IE 11 doesn't have it
function assign(...args) {
  return args
    .filter((a) => !!a)
    .reduce((accumulator, object) => {
      Object.keys(object).forEach((key) => {
        accumulator[key] = object[key];
      });
      return accumulator;
    }, {});
}

class Loader {
  init: (settings: *) => void;

  getLocalStorageData: () => void;

  getSettings: (localState: *) => void;

  docReady: (docuement: *, callback: void) => void;

  build: () => void;

  iframeConstructor: () => void;

  tryToLoadBundlesInsideIframe: () => void;

  tryToLoadBundles: () => void;

  getBrowserLang: () => void;

  processQueue: () => void;

  buildBundles: () => void;

  clearLoadCheck: () => void;

  addStyle: () => void;

  base_path: ?string;

  settings: *;

  loadCheck: *;

  subscriptions: *;

  constructor() {
    if (!window._dixa) {
      throw new Error(
        `Dixa Widget: ReferenceError missing "window._dixa" The widget needs to load from the snippet script.`,
      );
    }

    this.base_path = window.dixaPublicPath != null ? window.dixaPublicPath : '';

    this.getLocalStorageData();

    this.subscriptions = {};

    window._dixa.handler = (args) => {
      switch (args[0]) {
        case 'api.subscribe': {
          this.subscribe(args[1], args[2]);
          break;
        }
        case 'api.unsubscribe': {
          this.unsubscribe(args[1], args[2]);
          break;
        }
        default: {
          const stringifiedArgs = JSON.stringify(args, (k, v) => {
            if (typeof v === 'function') {
              return v.toString();
            }
            return v;
          });
          // eslint-disable-next-line no-unused-expressions
          document.getElementById('dixa-widget-wrapper') !== null &&
            document
              .getElementById('dixa-widget-wrapper')
              .contentWindow.postMessage(stringifiedArgs, '*');
          break;
        }
      }
    };
  }

  hasAnActiveConversation = (localState) => {
    if (localState && localState.conversation && localState.conversation.csid) {
      localStorage.removeItem('dixa-widget');
      return false;
    }
    if (
      localState &&
      localState.conversation &&
      localState.conversation.token
    ) {
      return true;
    }
    return false;
  };

  getLocalStorageData = () => {
    const localState = JSON.parse(localStorage.getItem('dixa-widget'));
    if (localState && localState.user && localState.user.bannedAt) {
      const orgId = localState.settings.settings.org_id;
      const userID = localState.user.currentUser.id;

      const xhr = new XMLHttpRequest();
      xhr.open(
        'GET',
        `${config.REST_API_URL}v1/bans/user/${userID}/is_banned?organization_id=${orgId}&channel=widgetchat`,
      );

      xhr.send();
      xhr.onload = ({ target: { response: responseAsString } }) => {
        const responseAsJson = JSON.parse(responseAsString);
        if (responseAsJson.data.result === true) {
          //  Stop execution user is still banned.
        } else {
          // Update the localStorage with user not banned and continue.
          const updatedLocalState = assign({}, localState);
          updatedLocalState.user.bannedAt = null;
          localStorage.setItem(
            'dixa-widget',
            JSON.stringify(updatedLocalState),
          );
          this.getSettings(updatedLocalState);
        }
      };
      xhr.onerror = (error) => {
        console.error(error);
      };
    } else {
      this.getSettings(localState);
    }
  };

  getSettings = (localState) => {
    const transactionId =
      config.WIDGET_STATE &&
      config.WIDGET_STATE.settings &&
      config.WIDGET_STATE.settings.transactionId
        ? config.WIDGET_STATE.settings.transactionId
        : null;

    const widgetOrigin = `${location.protocol}//${location.host}${location.pathname}`;

    const menuHistory =
      localState &&
      localState.settings &&
      localState.settings.settings &&
      localState.settings.settings.menu_history
        ? localState.settings.settings.menu_history
        : null;
    const currentView =
      localState && localState.view && localState.view.currentView
        ? localState.view.currentView
        : null;
    const request = new XMLHttpRequest();

    window._dixa.initialState = {
      activeConversation: this.hasAnActiveConversation(localState),
      currentView,
    };

    request.open(
      'PUT',
      `${config.REST_API_URL}v1/widgets/${window._dixa.wid}/settings`,
    );
    request.addEventListener(
      'load',
      ({ target: { response: responseAsJson } }) => {
        const response = JSON.parse(responseAsJson);
        const thereAreErros = response.errors && response.errors.length !== 0;
        if (thereAreErros) {
          response.errors.forEach((error) => {
            console.error(error);
          });
          localStorage.removeItem('dixa-widget'); // errors could be due to a stale redux state in the local storage
          throw new Error('widget cannot be loaded.');
        }
        const localSettings = config.SETTINGS;
        const runtimeSettings = window._dixa.settings || {};
        const localSettingsWithDefault =
          localSettings !== undefined && localSettings !== null
            ? localSettings
            : {};
        const settings = assign(
          response.data,
          localSettingsWithDefault,
          runtimeSettings,
        );
        this.init(settings);
      },
    );
    request.addEventListener('error', console.error);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

    const body = {
      url: widgetOrigin,
    };
    if (transactionId) {
      body.transaction_id = transactionId;
    }
    if (menuHistory) {
      body.menu_history = menuHistory;
    }
    request.send(JSON.stringify(body));
  };

  init = (settings = null) => {
    if (settings === null) {
      return;
    }

    const { activeConversation } = window._dixa.initialState;

    // We only establish ws connection when the widget is not in hidden mode.
    // we poll the settings until the settings response tell us to show the widget.
    // if we have a active conversation we continue.
    const delay = 30000;
    if (!activeConversation && settings.show_widget === false) {
      setTimeout(() => {
        this.getLocalStorageData();
      }, delay);
      return;
    }

    this.settings = settings;
    this.settings.locale = this.getBrowserLang();
    this.settings.wid = window._dixa.wid;

    if (document.body != null) {
      return this.iframeConstructor();
    }
    return this.docReady(document, this.iframeConstructor());
  };

  // We wait until document is ready before fire callback.
  docReady = (doc, callback) => {
    if (doc.addEventListener) {
      return doc.addEventListener(
        'DOMContentLoaded',
        function () {
          doc.removeEventListener('DOMContentLoaded', arguments.callee, false);
          return callback();
        },
        false,
      );
    }
    if (doc.attachEvent) {
      // If IE event model is used.
      // Ensure firing before onload.
      return doc.attachEvent('onreadystatechange', function () {
        if (doc.readyState === 'complete') {
          doc.detachEvent('onreadystatechange', arguments.callee);
          return callback();
        }
      });
    }
  };

  iframeConstructor = () => {
    const windowWidth =
      document && document.body && document.body.clientWidth
        ? document.body.clientWidth
        : 0;
    const typeClass = windowWidth > 992 ? 'medium' : 'small';
    let iframeStyles =
      positionsStyles.small.minimized_view[
        this.settings.small.minimized_view.position
      ];

    this.settings.device = typeClass;

    if (typeClass === 'medium') {
      iframeStyles =
        positionsStyles.medium.minimized_view[
          this.settings.medium.minimized_view.position
        ];
    }

    const ENABLE_REACT_DEVTOOLS =
      process.env.NODE_ENV === 'development'
        ? '<script>window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = window.parent.__REACT_DEVTOOLS_GLOBAL_HOOK__;</script>'
        : '';

    const html = `
      <!DOCTYPE html>
      <html class="${typeClass}" >
        <head>
          <meta charset="utf-8">
          <base target="_parent" >
          <title>Dixa widget</title>
          <meta http-equiv="x-ua-compatible" content="ie=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          <meta name="robots" content="noindex, nofollow">
          ${ENABLE_REACT_DEVTOOLS}
        </head>
        <body></body>
      </html>`;

    const divWrapper = document.createElement('div');
    const iframeElm = document.createElement('iframe'); // Build the iframe tag.

    divWrapper.id = 'dixa-iframe-wrapper';
    divWrapper.setAttribute(
      'style',
      '-webkit-overflow-scrolling: touch;z-index: 18654331;',
    ); // Set the style attr on the div-wrapper.

    iframeElm.id = 'dixa-widget-wrapper'; // Set the id attr on the iframe.
    iframeElm.setAttribute('style', iframeStyles); // Set the style attr on the iframe.
    iframeElm.setAttribute('scrolling', 'no'); // Remove scrolling for IE
    iframeElm.setAttribute('height', '0'); // Remove scrolling for IE
    iframeElm.setAttribute('title', 'Dixa widget'); // Add title to widget for accessbility.

    divWrapper.appendChild(iframeElm);

    if (document.body) {
      document.body.appendChild(divWrapper); // Append the iframe.
    } else {
      document.getElementsByTagName('script')[0].appendChild(divWrapper); // Append the iframe the first script tag we find.
    }
    this.addStyle(); // We add the widget styling for small widget (used when in conversation mode on mobile view (small view)).

    window._dixa.settings = this.settings;

    // @TODO we need to rewrite this to add tags function.
    iframeElm.contentWindow.document.open();
    iframeElm.contentWindow.document.write(html);
    iframeElm.contentWindow.document.close();

    this.tryToLoadBundles();
  };

  tryToLoadBundles = () => {
    const dixaWidgetIFrame = document.getElementById('dixa-widget-wrapper');
    const iframeDocument = dixaWidgetIFrame.contentDocument;

    if (iframeDocument.readyState === 'complete') {
      return this.buildBundles();
    }
    // eslint-disable-next-line no-return-assign
    return (this.loadCheck = setInterval(() => {
      if (iframeDocument.getElementById('dixa-bundle') !== null) {
        return this.clearLoadCheck();
      }
      return this.tryToLoadBundles();
    }, 100));
  };

  // We remove the setInterval after iframe document is complete.
  clearLoadCheck = () => clearInterval(this.loadCheck);

  buildBundles = () => {
    const dixaWidgetIFrame = document.getElementById('dixa-widget-wrapper');
    const iframeDocument = dixaWidgetIFrame.contentDocument;

    // Set the publich path in the iframe windown too.
    dixaWidgetIFrame.contentWindow.dixaPublicPath = window.dixaPublicPath;
    dixaWidgetIFrame.contentWindow._dixa = window._dixa;

    // Make a handshake on the messages between the parent docuement and the iframe.
    window.onmessage = (e) => {
      if (e.data !== null && e.data === 'handshake') {
        // console.warn("handshake received")
        return this.processQueue();
      }
      if (
        e.data &&
        !(e.data.source && e.data.source.indexOf('devtools') > -1)
      ) {
        try {
          this.handlePostMessage(JSON.parse(e.data));
        } catch (exception) {
          // console.warn('Could not parse widget postmessage', exception, e.data);
        }
      }
    };

    // Build the bundle script tag.
    const scriptTag = iframeDocument.createElement('script');
    scriptTag.async = 1;
    scriptTag.setAttribute('charset', 'utf-8'); // Used if the host site not is using utf-8 chatset.
    scriptTag.id = 'dixa-bundle';
    scriptTag.src = `${this.base_path}%HASH-FILENAME%`;
    return iframeDocument.querySelector('head').appendChild(scriptTag);
  };

  // The Method Queue Pattern.
  // http://calendar.perfplanet.com/2012/the-non-blocking-script-loader-pattern/
  processQueue = () => {
    const dixaWidgetIFrame = document.getElementById('dixa-widget-wrapper');
    const items = window._dixa.q;
    if (items && dixaWidgetIFrame) {
      return items.map((item) => window._dixa.handler(item));
    }
  };

  addStyle = () => {
    const styleNode = document.createElement('style');
    styleNode.type = 'text/css';
    // browser detection (based on prototype.js)
    const styling =
      'body.dixa-widget-small-open {overflow: hidden; position: fixed; overflow-y: hidden; width: 100%;height:100%;}';
    if (window.attachEvent && !window.opera) {
      styleNode.styleSheet.cssText = styling;
    } else {
      const styleText = document.createTextNode(styling);
      styleNode.appendChild(styleText);
    }
    document.getElementsByTagName('head')[0].appendChild(styleNode);
  };

  getBrowserLang = () => {
    const naviLang = navigator.language || navigator.browserLanguage;
    const localeArray = naviLang.split('-');
    // Some browsers returns the language in all lowercase, we fix it here.
    const locale = localeArray[1]
      ? `${String(localeArray[0])}-${String(localeArray[1].toUpperCase())}`
      : naviLang;

    return getCommonLocale(locale);
  };

  handlePostMessage = (data) => {
    if (data.event && typeof this.subscriptions[data.event] !== 'undefined') {
      this.subscriptions[data.event].forEach((subscription) => {
        setTimeout(() => {
          subscription(data.payload);
        }, 1000);
      });
    }
  };

  subscribe(event, callback) {
    if (typeof this.subscriptions[event] === 'undefined') {
      this.subscriptions[event] = [];
    }
    this.subscriptions[event].push(callback);
  }

  unsubscribe(event, callback) {
    if (typeof this.subscriptions[event] !== 'undefined') {
      this.subscriptions[event] = this.subscriptions[event].filter(
        (fn) => callback !== fn,
      );
    }
  }
}

(() => new Loader())();
